/**
 * Delivery page actions types.
 */
export const FETCH_CHECKOUT_CART_DETAILS = 'FETCH_CHECKOUT_CART_DETAILS';

export const FETCH_CHECKOUT_CART_DETAILS_DELIVERY =
  'FETCH_CHECKOUT_CART_DETAILS_DELIVERY';

export const DELIVERY_PAGE_API_LOADING = 'DELIVERY_PAGE_API_LOADING';

export const UPDATE_DELIVERY_SHIPPING_METHOD_DATA =
  'UPDATE_DELIVERY_SHIPPING_METHOD_DATA';

export const SET_UPDATE_DELIVERY_SHIPPING_METHOD_LOADING =
  'SET_UPDATE_DELIVERY_SHIPPING_METHOD_LOADING';

export const DELIVERY_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS =
  'DELIVERY_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS';

export const DELIVERY_USABLE_SHIPPING_INFO_SUCCESS =
  'DELIVERY_USABLE_SHIPPING_INFO_SUCCESS';

export const DELIVERY_USABLE_SHIPPING_INFO_LOADING =
  'DELIVERY_USABLE_SHIPPING_INFO_LOADING';

export const RESTRICTED_CART_PRODUCTS_SUCCESS =
  'RESTRICTED_CART_PRODUCTS_SUCCESS';

export const SELECTED_ADDRESS_NICK_NAME = 'SELECTED_ADDRESS_NICK_NAME';

export const RESET_RESTRICTED_PRODUCTS = 'RESET_RESTRICTED_PRODUCTS';

export const ENABLE_DELIVERY_CONTINUE = 'ENABLE_DELIVERY_CONTINUE';

export const RESET_SHIPPING_OPTIONS = 'RESET_SHIPPING_OPTIONS';

export const RESET_DELIVERY_STEP = 'RESET_DELIVERY_STEP';

export const FETCH_CHECKOUT_CART_DETAILS_PAYMENT =
  'FETCH_CHECKOUT_CART_DETAILS_PAYMENT';

export const PAYMENT_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS =
  'PAYMENT_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS';

export const PAYMENT_USABLE_SHIPPING_INFO_SUCCESS =
  'PAYMENT_USABLE_SHIPPING_INFO_SUCCESS';

export const ENABLE_PAYMENT_CONTINUE = 'ENABLE_PAYMENT_CONTINUE';

export const RESET_PAYMENT_STEP = 'RESET_PAYMENT_STEP';

export const FETCH_CHECKOUT_CART_DETAILS_REVIEW =
  'FETCH_CHECKOUT_CART_DETAILS_REVIEW';

export const FETCH_CART_PRODUCTS_REVIEW = 'FETCH_CART_PRODUCTS_REVIEW';

export const REVIEW_USABLE_SHIPPING_INFO_SUCCESS =
  'REVIEW_USABLE_SHIPPING_INFO_SUCCESS';

export const UPDATE_REVIEW_SHIPPING_METHOD_DATA =
  'UPDATE_REVIEW_SHIPPING_METHOD_DATA';

export const RESET_REVIEW_STEP = 'RESET_REVIEW_STEP';

export const CARDINAL_ORDER_REQUEST = 'CARDINAL_ORDER_REQUEST';

export const CARDINAL_ORDER_REQUEST_SUCCESS = 'CARDINAL_ORDER_REQUEST_SUCCESS';

export const CARD_DENIED = 'CARD_DENIED';

export const RESET_ASSIGN_STORE_ACTION_SUCCESS =
  'RESET_ASSIGN_STORE_ACTION_SUCCESS';

export const UPDATE_EMAIL_OPTIN_STATUS =
  'UPDATE_EMAIL_OPTIN_STATUS';
